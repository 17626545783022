import api from "./api";
import TokenService from "./token.service";
import FS from './const'


class AuthService {
    login({username, password}) {

        let data = `grant_type=password&username=${username}&password=${password}`;
        return api
            .post("/o/token/", data, {
                auth: {username: FS.AT, password: FS.CS},
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
            .then((response) => {
                if (response.data.access_token) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error)
            });
    }

    logout() {
        TokenService.removeUser();
    }

    groups = () => api.get('/user/groups')

    register({username, email, password}) {
        return api.post("/auth/signup", {
            username,
            email,
            password
        });
    }
}

export default new AuthService();
