<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <template v-if="loggedIn">
        <li v-for="route of nav" :key="route.name" class="nav-item">
          <router-link v-if="inGroup(route.meta.group)" class="nav-link" :to="route">
            {{ route.meta.title }}
          </router-link>
        </li>
      </template>
      <li v-else class="nav-item">
        <router-link class="nav-link" to="/login">Inicia sesión</router-link>
      </li>
    </ul>
    <ul class="navbar-nav" v-if="loggedIn">
      <li class="nav-item"><a href="#" class="nav-link" @click="salir()">Salir</a></li>
    </ul>
  </nav>

  <div class="container-fluid ">
    <nav  aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Inicio</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ $route.meta.title }}</li>
      </ol>
    </nav>

    <router-view/>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { routes } from './router/index'

export default {
  computed: {
    ...mapState('auth', ['status']),
    ...mapGetters('auth', ['inGroup']),
    nav: () => routes.filter( r => r.beforeEnter ),
    loggedIn() { return this.status.loggedIn; },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    salir() {
      this.logout()
      this.$router.push('/')
    }
  },
};
</script>
<style>

.bg-gob { background-color: #9d2449; color: white; }

nav {
  padding: 4px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>
