import axiosInstance from "./api";
import TokenService from "./token.service";
import FS from './const'

const setup = (store) => {
    axiosInstance.interceptors.request.use(

        (config) => {


            const token = TokenService.getLocalAccessToken();

            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                //config.headers["x-access-token"] = token; // for Node.js Express back-end

            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== "/o/token/" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        console.log("Refrescando")

                        let rtoken = TokenService.getLocalRefreshToken();
                        let data = `grant_type=refresh_token&refresh_token=${rtoken}&client_id=${FS.AT}&client_secret=${FS.CS}`;
                        const rs = await axiosInstance.post("/o/token/",data, {
                            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        });
                        const { accessToken } = rs.data.access_token;
                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.setUser(rs.data)
                        console.log(accessToken)
                        store.token=TokenService.getLocalRefreshToken();
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        store.dispatch('auth/logout')
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;