import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@fortawesome/fontawesome-free/css/all.css"
import "dropzone/dist/dropzone.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store);



createApp(App).use(router).use(store).mount('#app');


