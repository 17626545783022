import { createStore } from 'vuex'
import { auth } from "./auth.module";
export default createStore({
  state: {
    user: null,
    token: null,
  },
  getters: {
    isLoggedIn(state) {

      return !!state.token;
    },
    getToken(state){
      return state.token;
    },
    headers(state){
      return {
        Authorization: "Bearer "+ state.token
      }
    }

  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
  },
  modules: {
    auth,
  }
})
