import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import store from '../store/index.js'
const beforeEnter = (to, from, next) => {
    store.state.auth.status.loggedIn ? next() : next('/login')
}

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: { title: 'Inicio sesión' }
  },
  {
    path: '/carga',
    name: 'carga',
    component: () => import(/* webpackChunkName: "about" */ '../views/CargaView.vue'),
    meta: { title: 'Carga de Archivos', group: 'Produccion' },
    beforeEnter,
  },
  {
    path: '/listado',
    name: 'listado',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListadoArchivoView.vue'),
    meta: { title: 'Listado de Archivos' },
    beforeEnter,
  },
  {
    path: '/recursos/:id?',
    name: 'recursos',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListadoRecursosView'),
    meta: { title: 'Verificación de recursos' },
    beforeEnter,
  },
  {
    path: '/colecciones',
    name: 'colecciones',
    component: () => import(/* webpackChunkName: "about" */ '../views/ColeccionesView'),
    meta: { title: 'Colecciones' },
    beforeEnter,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
})

export default router
