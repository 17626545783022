<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    this.loggedIn ?
      this.$router.push('/listado') :
      this.$router.push("/login");
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  }
}
</script>
